<template>
  <div class="resetpwd" style="margin-top: 50px">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="phone_no"
          label="账号"
          type="tel"
          placeholder="请输入帐号"
          clearable
          maxlength="11"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="pwd"
          label="新密码"
          type="password"
          placeholder="请输入6-18位新密码"
          clearable
          maxlength="18"
          :rules="[{ required: true }]"
        />

        <van-field
          v-model="sms_code"
          center
          clearable
          type="digit"
          label="短信验证码"
          placeholder="请输入短信验证码"
          maxlength="6"
          :rules="[{ required: true }]"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click="sendSMS"
              :disabled="noSend"
              :rules="[{ required: true }]"
              >{{ sendSmsText }}</van-button
            >
          </template>
        </van-field>
      </van-cell-group>   
      <div style="margin: 60px 18px">
        <van-button round block type="primary" native-type="submit">
          修改密码
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { Toast } from "vant";
import axios from "axios";
import { useRouter } from "vue-router";

export default {
  setup() {
    let phone_no = ref("");
    let pwd = ref("");
    let sms_code = ref("");
    const sendSmsText = ref("发送验证码");
    const counter = ref(90);
    const noSend = ref(false);
    const router = useRouter();
    let timer;
    const onClickLeft = () => {
      router.go(-1);
    };

    const sendSMS = () => {
      sendSmsText.value = "发送验证码";
      if (!isPone(phone_no.value)) {
        Toast("手机号码格式不正确");
        return;
      }
      if (!isPwd(pwd.value)) {
        Toast("密码格式为6-18位数字字母或其组合");
        return;
      }
      axios({
        url: "/api/user/verify",
        method: "post",
        params: {
          phone_no: phone_no.value,
          resetpwd: 1, //找回密码时请求 1
        },
      })
        .then((res) => {
          if (res.data.code == 10000) {
            countDown();
            Toast("验证码已发送");
          } else {
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    };

    onMounted(() => {
      /* eslint-disable */
      upsdk.pluginReady(function () {
        upsdk.setNavigationBarTitle({
          title: "修改密码",
        });
        upsdk.setTitleStyle({
          backBtnVisible: "1",
          navBackgroundColor: "0xff1989fa",
        });
      });

    });

    function isPone(phone) {
      var regPhone = /^[1][0-9]{10}$/;
      if (regPhone.test(phone)) {
        return true;
      } else {
        return false;
      }
    }

    function isPwd(pwd) {
      var regPwd = /^[a-zA-Z0-9~!@#$%^&*?]{6,18}$/;
      if (regPwd.test(pwd)) {
        return true;
      } else {
        return false;
      }
    }

    function isSmsCode(code) {
      var regCode = /^[0-9]{6}$/;
      if (regCode.test(code)) {
        return true;
      } else {
        return false;
      }
    }
    function set(){
        noSend.value = true;
        counter.value--;
        sendSmsText.value = `(${counter.value}秒)后重新发送`;
        if (counter.value == 0) {
          // 当计时小于零时，取消该计时器
          clearInterval(timer);
          sendSmsText.value = "发送验证码";
          counter.value = 90;
          noSend.value = false;
        }
        return set;
    }
    function countDown() {
      timer = setInterval(set(), 1000);
    }
    const onSubmit = () => {
      if (!isSmsCode(sms_code.value)) {
        Toast("验证码格式错误");
        return;
      }
      axios({
        url: "/api/user/find_reset_pwd",
        method: "post",
        params: {
          phone_no: phone_no.value,
          verify_code: sms_code.value,
          password: pwd.value,
        },
      })
        .then((res) => {
          if (res.data.code == 10020) {
            Toast("密码修改成功");
            router.go(-1);
          }
        })
        .catch((err) => {
          Toast(err.message);
          // console.log("请求catch->", res);
        });
    };
    return {
      phone_no,
      pwd,
      sms_code,
      onSubmit,
      sendSMS,
      onClickLeft,
      sendSmsText,
      counter,
      noSend,
    };
    // const router = useRouter();
  },
};
</script>
